import {createRouter, createWebHistory} from 'vue-router'
import {defineAsyncComponent} from 'vue'

const router = createRouter({
    // history: createWebHashHistory(),  // hash 模式
    history: createWebHistory(),  // history 模式
    routes: [
        {
            path: '/',
            name: 'SearchPage',
            component: defineAsyncComponent(() => import(`./view/SearchPage`)),
            meta: {
                title: 'ZEN',
            },
        },
        {
            path: '/ResultPage',
            name: 'ResultPage',
            component: defineAsyncComponent(() => import(`./view/ResultPage`)),
            meta: {
                title: 'ZEN',
            },
        },


    ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // console.log(to, from)
    if (to.meta.title) {
        document.title = `${to.meta.title}`;
    }
    next()
})

router.afterEach((to, from) => {
    console.log(to, from)
    // console.log('afterEach')
})

export default router
