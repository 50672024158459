<template>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
  <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <!--  <div id="app">-->
    <router-view/>
  <!--  </div>-->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import vid from './components/vid.vue'

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // HelloWorld,
    // vid
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/

}

.box {
  position: relative;
  z-index: 2;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 4px;

}
</style>
