import {createApp} from 'vue';
import App from './App.vue';
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import axios from "axios";


const app = createApp(App)
/* 挂载全局对象 start */
app.config.globalProperties.$axios = axios

/* 挂载全局对象 end */
app.use(ElementPlus).use(router)
app.mount('#app');
